export const getDateObjectFromContentfulDateString = (contentfulDateString) => {
  const [year, month, day] = contentfulDateString.split(`-`).map(d => parseInt(d))

  const date = new Date()
  date.setYear(year)
  date.setMonth(month - 1)
  date.setDate(day)
  date.setHours(0, 0, 0, 0)

  return date
}

export const nowIsBetweenContentfulDates = (
  contentfulStartDate,
  contentfulEndDate
) => {
  const startDate = getDateObjectFromContentfulDateString(contentfulStartDate)
  const endDate = getDateObjectFromContentfulDateString(contentfulEndDate)
  const today = new Date()

  console.log(startDate, endDate, today)

  return endDate > today && today > startDate
}