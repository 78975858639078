import React from "react"
import styled from "styled-components"
import { colors, fontDecor, fontBody } from "../utils/styles"
import { FaArrowRight } from "react-icons/fa"
import { graphql, useStaticQuery } from "gatsby"

/**
 * Begin Styled Components
 */

const SpecialsWrapper = styled.section`
  align-items: center;
  background: ${colors.secondaryColor};
  color: ${colors.mainBlack};
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  justify-content: center;
  outline-offset: -0.75rem;
  outline: 0.1rem solid black;
  padding: 2rem 0;
  text-align: center;
  text-transform: uppercase;

  & > header,
  & > footer {
    background: ${colors.secondaryColor};
  }
`

const Header = styled.h3`
  font: ${fontBody({ size: `1rem` })};
  padding-bottom: 0.5rem;
`

const DayOfWeek = styled.span`
  font: ${fontDecor({ size: `1.35rem` })};
  text-transform: none;

  &:after {
    content: ":";
  }
`

const Special = styled.span`
  font: ${fontBody({ size: `1.5rem`, weight: 700 })};
  padding: 0 0.5rem;
`

const Stipulation = styled.span`
  font: ${fontBody({ size: `1rem` })};
  font-style: italic;
  margin-top: -0.1rem;
  padding: 0 0.5rem;

  &:before {
    content: "(";
  }

  &:after {
    content: ")";
  }
`

const DefaultMsg = styled.p`
  font-size: 1rem;
  margin-bottom: 0;
  padding: 0 2rem;
  text-transform: none;
  text-wrap: balance;
`

const Footer = styled.footer`
  margin-top: 1rem;

  a {
    align-items: center;
    background: ${colors.mainBlack};
    color: ${colors.secondaryColor};
    display: flex;
    gap: 0.35rem;
    padding: 0.5rem 1rem;

    svg {
      transition: translate 0.2s;
    }

    &:hover {
      color: ${colors.mainWhite};

      svg {
        translate: 0.15rem 0;
      }
    }
  }
`

/**
 * End Styled Components
 */

const DailySpecials = () => {
  const [special, setSpecial] = React.useState(null)

  const data = useStaticQuery(graphql`
    query {
      dailySpecials: allContentfulDailySpecial(
        filter: { restaurant: { name: { eq: "Azalea Bar & Kitchen" } } }
      ) {
        nodes {
          dayOfWeek
          name
          stipulation
        }
      }
      dailySpecialsMenu: contentfulRestaurant(
        name: { eq: "Azalea Bar & Kitchen" }
      ) {
        dailySpecialsMenu {
          file {
            url
          }
        }
      }
    }
  `)

  const menuUrl = data.dailySpecialsMenu?.dailySpecialsMenu?.file?.url || ``
  const specials = React.useMemo(() => data.dailySpecials?.nodes || [], [data])

  React.useEffect(() => {
    const day = new Date().getDay()
    const days = [`Sunday`, `Monday`, `Tuesday`, `Wednesday`, `Thursday`, `Friday`, `Saturday`]
    const today = days[day]

    const todaysSpecial = specials.find(spec => spec.dayOfWeek === today)
    if (!todaysSpecial) return

    setSpecial({
      dayOfWeek: todaysSpecial.dayOfWeek,
      name: todaysSpecial.name,
      stipulation: todaysSpecial.stipulation,
    })

  }, [specials])

  const months = [`January`, `February`, `March`, `April`, `May`, `June`, `July`, `August`, `September`, `October`, `November`, `December`]
  const date = new Date()
  const todaysDate = `${months[date.getMonth()]} ${date.getDate()}`

  if (data.dailySpecials.nodes.length === 0) return <></>

  return (
    <SpecialsWrapper>
      <header>
        <Header>Daily Specials{` // ${todaysDate}`}</Header>
      </header>
      {special ? (
        <>
          <DayOfWeek>{special.dayOfWeek}</DayOfWeek>
          <Special>{special.name}</Special>
          {special.stipulation && <Stipulation>{special.stipulation}</Stipulation>}
        </>
      ) : (
        <DefaultMsg>
          Stop in Wednesday through Sunday to enjoy one of our daily specials.
        </DefaultMsg>
      )}
      {menuUrl && (
        <Footer>
          <a href={menuUrl}>
            See {special && `More `}Daily Specials 
            <FaArrowRight size="0.9rem"  />
          </a>
        </Footer>
      )}
    </SpecialsWrapper>
  )
}

export default DailySpecials
